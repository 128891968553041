<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <q-card flat bordered class="q-pa-sm">
        <q-card-section class="q-gutter-sm">
          <base-input-text v-bind="formInputProps('label')" :color="color"
            v-model="formData.label" ref="label" />

          <base-input-text v-bind="formInputProps('description')" :color="color"
            v-model="formData.description" ref="description" />
        </q-card-section>
      </q-card>

      <div v-if="authorUser" class="row q-gutter-md">
        <div class="col">
          <h6>{{$t('tasks.fields.auteur.label')}}</h6>
          <UserCard :user="authorUser" />
        </div>

        <div class="col">
          <h6>{{$t('tasks.fields.destinataire.label')}}</h6>
          <UserCard v-if="destUser.value" :user="destUser.value" @menuClick="onUserMenuClick" />

          <search-box v-else
            model_type="user"
            :value="destUser.value"
            @input="updateDestUser"
            :label="$t('users.user')" label_field="display_name"
            :color="color" />
        </div>
      </div>

      <div class="row">
        <div class="col col-12">
          <h6>{{$t('tasks.fields.model.label')}}</h6>

          <LinkedObjectCard v-if="linkedObj" :linkedObj="linkedObj" @menuClick="onModelAction" />

          <search-box v-else
            @input="onSelectLinkedObj"
            :label="$t('tasks.fields.model.label')"
            :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
            :color="color" />
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-sm">
      <q-list class="full-width q-gutter-y-xs">
        <q-item class="q-pa-none">
          <base-input-date v-bind="formInputProps('date_cible')" :color="color"
            v-model="formData.date_cible" ref="date_cible" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-date v-bind="formInputProps('date_rappel')" :color="color"
            v-model="formData.date_rappel" ref="date_rappel" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('qualification')" :color="color"
            v-model="formData.qualification" ref="qualification" />
        </q-item>
      </q-list>

      <q-btn :color="color" class="full-width" size="sm" unelevated type="submit">{{$t('form.submit')}}</q-btn>
      <q-btn :color="color" class="full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
      <q-btn color="negative full-width" size="sm" unelevated icon-right="delete"
        v-if="task" :disable="!deletable" @click.native="destroy">
        {{$t('delete')}}
      </q-btn>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'

import { UserCard } from '../../components/users'
import { LinkedObjectCard } from '../../components/linked-objects'

export default {
  name: 'TaskDetails',
  mixins: [BaseForm],
  components: { UserCard, LinkedObjectCard },
  props: {
    'form_model': { type: String, default: 'tasks' },
    'action': { type: String, default: 'tasks/saveTask' },
    'model_type': String,
    'model_object': Object,
    'statut': String,
    'description': String
  },

  data () {
    return {
      authUser: null,
      destUser: { type: 'user', value: null },
      linkedObj: null
    }
  },

  computed: {
    ...mapGetters({
      task: 'tasks/getCurrentTask',
      connectedUser: 'auth/getCurrentUser'
    }),

    color () {
      if (this.formData.model_type === 'entity') { return 'reseau' }
      if (this.formData.model_type === 'cible') { return 'per' }
      return 'tasks'
    },

    label () {
      return !this.$route.params.id ? this.$t('tasks.add_task') : this.$t('tasks.edit_task')
    },

    statusOptions () {
      return ['active', 'inactive']
    },

    authorUser () {
      if (this.$route.name === 'task-show') {
        return this.task ? this.task.auteur : null
      } else {
        return this.connectedUser
      }
    },

    isCurrentUserDestinataire () {
      return this.destUser && this.connectedUser ? this.destUser.id === this.connectedUser.id : false
    },

    deletable () {
      return this.task && this.task.droits && this.task.droits.droits.indexOf('deletable') >= 0
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        if (newVal && this.task) {
          this.formData = {
            ...this.formData,
            ...newVal.map(({ id, options }) => ({ id, options }))
              .reduce((acc, { id, options }) => ({
                ...acc, [id]: options ? options.find(({ value }) => value === this.task[id]) : this.task[id]
              }), {}),
            auteur: this.task.auteur.id,
            destinataire: this.task.destinataire.id
          }

          this.authUser = this.task.auteur
          this.destUser = {
            type: 'user',
            value: { ...this.task.destinataire,
              actions: [{ label: this.$t('tasks.remove_destinataire'), payload: { name: 'remove-destinataire' } }]
            }
          }

          if (this.task.model) {
            const { model_type, model } = this.task
            let type
            if (model_type === 'App\\Entity') { type = 'entity' }
            if (model_type === 'App\\Wallet') { type = 'wallet' }
            if (model_type === 'App\\Agent') { type = 'agent' }
            if (model_type === 'App\\Cible') { type = 'cible' }

            this.linkedObj = this.createLinkedObject(type, model)
          }
        } else {
          if (newVal) this.formData.qualification = newVal.find(attr => attr.id === 'qualification').options.find(option => option.value === 4)
          if (this.statut) this.formData.statut = { value: this.statut, label: this.$t(`processes.status.${this.statut}`) }
          if (this.description) this.formData.description = this.description
          if (this.model_object) this.formData.label = this.model_object.label
        }
      },
      immediate: true
    },

    task (newVal, oldVal) {
      let droits_title
      if (newVal.destinataire.id === this.connectedUser.id) droits_title = 'destinataire'
      if (newVal.auteur.id === this.connectedUser.id) droits_title = 'auteur'
      this.$store.dispatch('tasks/fetchFormFields', { droits_title })
    },

    connectedUser: {
      handler (newVal, oldVal) {
        if (!this.$route.params.id) {
          this.formData.auteur = newVal ? newVal.id : null
        }
      },
      immediate: true
    },

    destUser: {
      handler (newVal, oldVal) {
        this.formData.destinataire = newVal.value ? newVal.value.id : null
      },
      deep: true
    },

    linkedObj (newVal, oldVal) {
      if (newVal && newVal.value) {
        this.formData.model_type = newVal ? newVal.type : null
        this.formData.model_id = newVal.value.value ? newVal.value.value : newVal.value.id
      } else {
        this.formData.model_type = null
        this.formData.model_id = null
        delete this.formData.model_type
        delete this.formData.model_id
      }
    },

    '$route.params': {
      handler (newVal, oldVal) {
        if (newVal.id) {
          this.formData.id = newVal.id
          this.$store.dispatch('tasks/getTask', newVal.id)
        } else {
          this.$store.commit('pages/setPageTitle', this.$t('tasks.add_task'))
          this.$store.dispatch('tasks/fetchFormFields', { droits_title: 'create' })
        }
      },
      immediate: true
    }
  },

  mounted () {
    if (this.model_object) {
      this.linkedObj = this.createLinkedObject(this.model_type, this.model_object)
    }
  },

  methods: {
    createLinkedObject (type, model) {
      return { type,
        value: { ...model,
          actions: [
            { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: model.id } } }
          ]
        }
      }
    },

    onSelectLinkedObj (obj) {
      this.linkedObj = { ...obj,
        'value.actions': [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.id } } }
        ]
      }
    },

    updateDestUser ({ value }) {
      this.destUser.value = { ...value,
        actions: [{ label: this.$t('tasks.remove_destinataire'), payload: { name: 'remove-destinataire' } }]
      }
    },

    onSave () {
      this.$store.dispatch('config/fetchCounts')
      this.$router.push({ name: 'tasks-list-tous' })
    },

    destroy () {
      this.$confirm(this.$t('tasks.delete_task_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('tasks/deleteTask', this.$route.params.id).then(() => {
          this.notifySuccess('tasks.delete_success')
          this.$router.go(-1)
        }).then(() => { this.$store.dispatch('config/fetchCounts') })
          .catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    },

    onModelAction ({ name, params }) {
      if (name === 'model-detach') {
        this.linkedObj = null
      }
    },

    onUserMenuClick ({ name, params }) {
      if (name === 'remove-destinataire') { this.destUser.value = null }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
