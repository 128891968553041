<template>
  <div class="content">
    <SousCiblesHierarchy v-if="type === 'cible'" :cibles="[linkedObj.value]" @menuClick="menuClick($event)"/>
    <base-card v-else v-bind="linkedObj.value" v-on="$listeners">
      <q-avatar v-if="type === 'agent' && value['PHOTO']" slot='avatar' size="3rem"><q-img :src="value['PHOTO']" :ratio="1" /></q-avatar>
      <q-avatar v-else slot='avatar' size="3rem" :color="color" text-color="white" :icon="icon" />

      <q-item-label>
        <strong><router-link :to="{ name: 'entity-show', params: { id: id } }">{{label}}</router-link></strong>
      </q-item-label>
    </base-card>
  </div>
</template>

<script>
import { SousCiblesHierarchy } from '../../components/sous-cibles'

export default {
  name: 'LinkedObjectCard',
  props: {
    'linkedObj': { type: Object,
      required: true,
      default: () => ({
        'type': { type: String, required: true },
        'value': { type: Object, required: true }
      }) }
  },
  components: { SousCiblesHierarchy },

  computed: {
    type () {
      return this.linkedObj.type
    },

    id () {
      return this.linkedObj.value.entity_id
        ? this.linkedObj.value.entity_id
        : this.linkedObj.value.entities && this.linkedObj.value.entities.length > 0
          ? this.linkedObj.value.entities[0].id
          : this.linkedObj.value.id
    },

    value () {
      console.log(this.linkedObj)
      return this.linkedObj.value
    },

    color () {
      if (this.type === 'agent' || this.type === 'wallet' || this.type === 'entity') { return 'reseau' }
      if (this.type === 'cible') { return 'per' }
      return 'agora'
    },

    label () {
      console.log(this.linkedObj)
      if (this.type === 'agent') { return this.value['TITULAIRE'] }
      if (this.type === 'wallet') { return this.value['NOMPTF'] }
      if (this.type === 'entity') { return this.value.label }
      if (this.type === 'cible') {
        // console.log(this.value)
        return `${this.$t(`sousCibles.types.${this.value.type}`)}`
      }
      return this.value.label
    },

    icon () {
      if (this.type === 'agent') { return 'person' }
      if (this.type === 'wallet') { return 'account_balance_wallet' }
      if (this.type === 'entity') { return 'account_balance_wallet' }
      if (this.type === 'cible') { return 'icon-icons_cible' }
      return ''
    }
  },

  methods: {
    menuClick ({ name, params }) {
      this.$emit('menuClick', { name, params })
    }
  }
}
</script>

<style>
</style>
