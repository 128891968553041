<template>
  <base-card v-bind="user" v-on="$listeners">
    <q-avatar slot='avatar' size="3rem" color="grey-6" text-color="white" icon="person" />

    <template slot='default'>
      <q-item-label>{{user.display_name}}</q-item-label>
      <div class="row justify-between items-center q-pa-sm">
        <q-item-label caption>{{user_region}}</q-item-label>
        <q-item-label caption>{{user_role}}</q-item-label>
      </div>
    </template>
  </base-card>
</template>

<script>
export default {
  name: 'UserCard',
  props: ['user'],
  computed: {
    user_role () {
      return this.user.roles && this.user.roles[0] && this.user.roles[0].display_name
    },
    user_region () {
      return this.user && this.user.region && this.$t(`users.fields.region_id.options.${this.user.region['CODEREG']}`)
    }
  }
}
</script>

<style>
</style>
